<template>
    <ClockingsDetailItemEditCard :confirmation-message="getConfirmationMessage" :title="getTitle"
      :update-store-action="getStoreAction" :update-store-action-payload="this.getClocking" :date="date" :userId="userId"
      :promises.sync="promises" :returnRoute="returnRoute">
      <template v-slot:form-fields>
        <WorkClockingForm v-model="workClocking" :break-time="breakTime"/>
      </template>
    </ClockingsDetailItemEditCard>
</template>
  
<script>
import ClockingsDetailItemEditCard from "@/views/calendar/forms/ClockingsDetailItemEditCard";
import { min, max, addMinutes, subMinutes } from 'date-fns';
import parse from "date-fns/parse";
import ClockingType from "@/shared/constants/ClockingType";
import WorkClockingForm from "../../../components/registerHours/WorkClockingForm.vue";
import {parseStringToDate, convertDateToWeekday} from "@/shared/utils/dateUtils";
import routeNames from "@/router/RouteNames";
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";

export default {
  name: "AddHoursView",
  props: {
    clockingId: {
      default: null,
      required: false,
      type: String
    },
    date: {
      default: null,
      required: false,
      type: String
    },
    userId:{
      default: null,
      required: false,
      type: String
    },
    returnRoute: {
      type: Object,
      required: false,
      default:() => ({name: routeNames.MODIFY_USER_HOURS_DAY})
    },
  },
  components: {
    ClockingsDetailItemEditCard,
    WorkClockingForm
  },
  created() {
    this.definePromises()
  },
  data() {
    return {
      promises: [
        Promise.all(
        [
          this.fetchDayOverview(), this.fetchStartTimePerWeek()
        ])
        .then( () => this.setWorkClockingTimes()), 
        this.fetchWorkClocking()
      ],
      startTimeMap: null,
      valid: false,
      workClocking: {
        id: this.clockingId,
        userId: this.getUserId(),
        startTime: null,
        endTime: null,
        comment: null,
        breakTime: null,
        day: this.date,
        clockingType: ClockingType.WORK,
      },
      breakTime: null
    }
  },
  computed: {
    getTitle() {
      if(this.clockingId){
        return this.isRegisterHoursForOtherEmployee ? `Bewerk uren ${this.getUser.name}` : "Bewerk uren"
      } else {
        return this.isRegisterHoursForOtherEmployee ? `Registreer uren ${this.getUser.name}` : "Registreer uren"
      }
    },
    isRegisterHoursForOtherEmployee(){
      // url contains userId param, meaning we are registering/editing hours of another user
      return this.$route.params.userId;
    },
    getUser(){
      let returnUser = this.$store.state.usersModule.user
      if (returnUser === null){
        returnUser = this.fetchUser()
      }
      return returnUser
    },
    getConfirmationMessage() {
      if(this.clockingId){
        return "Uren werden gewijzigd"
      } else {
        return "Uren werden toegevoegd"
      }
    },
    getBreakLength() {
      return this.$store.state.clockingsModule.breakLength;
    },
    // Returns data back to ClockingDetailItemEditCard where it will be dispatched to the appropriate action
    getClocking() {
      return  {
        ...this.workClocking,
        startTime: DetailedTimeStamp.fromUTCTime(this.workClocking?.startTime),
        endTime: DetailedTimeStamp.fromUTCTime(this.workClocking?.endTime),
      }

    },
    getStoreAction() {
      if (this.clockingId) {
        return "clockingsModule/updateWorkClocking"
      } else {
        return "clockingsModule/createWorkClocking"
      }
    },
  },
  methods: {
    definePromises(){
      if(this.isRegisterHoursForOtherEmployee) {
        this.promises.push(this.fetchUser())
      }
    },
    fetchDayOverview() {
      return this.$store.dispatch('clockingsModule/fetchDayOverview', {
        date: this.date,
        userId: this.getUserId()
      }).then(result => {this.dayOverview = result
        
      })
    },
    fetchWorkClocking(){
      if(this.clockingId != null){
        return this.$store.dispatch('clockingsModule/fetchClockingById', this.clockingId).then(result => 
        {
          this.workClocking = {
            ...result,
            startTime: DetailedTimeStamp.fromJson(result?.startTime).getUTCTimeAsDate(),
            endTime: DetailedTimeStamp.fromJson(result?.endTime).getUTCTimeAsDate(),
          }
          this.breakTime = result.breakTime
        })
      } else {
        return null
      }
    },
    fetchStartTimePerWeek() {
      return this.$store.dispatch('clockingsModule/fetchStartTimePerWeekFilledNulls',
          this.getUserId()).then( result => this.startTimeMap = result)
    },
    fetchUser(){ 
      return this.$store.dispatch('usersModule/fetchUser', this.getUserId())
    },
    getStartTime() {
      // Are there clockings on this day
      if (this.$store.state.clockingsModule.clockings.length > 0) {
        // Convert clockings to Date objects of endtime
        const mappedClockings = this.$store.state.clockingsModule.clockings.map(c => DetailedTimeStamp.fromJson(c.endTime).getUTCTimeAsDate())
        // Get latest endtime
        const maxClocking = max(mappedClockings)

        // If endtime is not 23:59, return latest endtime
        if (maxClocking.getHours() < 23 || maxClocking.getMinutes() < 59) {
          return maxClocking
        }
      }
      const defaultStartTime = new Date(this.getParsedDay())
      
      let dateWithTimeString = this.startTimeMap.weekdaysWithTime[convertDateToWeekday(defaultStartTime)];

      let dateWithTime = parseStringToDate(dateWithTimeString);

      defaultStartTime.setHours(dateWithTime.getHours())
      defaultStartTime.setMinutes(dateWithTime.getMinutes())

      return defaultStartTime
    },
    getEndTime() {
      let calculatedEndTime = new Date(this.workClocking?.startTime)

      //Calculate the endtime based on registered hours and expected hours
      calculatedEndTime = addMinutes(calculatedEndTime, this.$store.state.clockingsModule.expectedHours * 60)
      calculatedEndTime = subMinutes(calculatedEndTime, Math.min(this.$store.state.clockingsModule.expectedHours, this.$store.state.clockingsModule.registeredHours) * 60)

      // Add breaktime to endtime if not registered
      if (!this.$store.state.clockingsModule.breakTime && this.$store.state.clockingsModule.expectedHours > 0) {
        calculatedEndTime = addMinutes(calculatedEndTime, this.getBreakLength)
      }


      // Make sure endTime doesn't exceed 23:59
      const maxDate = new Date(this.getParsedDay())
      maxDate.setHours(23)
      maxDate.setMinutes(59)
      return min([calculatedEndTime, maxDate])
    },
    getUserId() {
      return this.$route.params.userId ? this.$route.params.userId : this.$store.state.authModule.user.id
    },
    getParsedDay() {
      return parse(this.date, 'yyyy-MM-dd', new Date())
    },
    setWorkClockingTimes() {
      if (!this.clockingId) {
          this.workClocking.startTime = this.getStartTime()
          this.workClocking.endTime = this.getEndTime()
        }
    }
  },
}
</script>
